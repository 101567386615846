
  import {ref,
    watch} from "vue";
  import {ContentLoader} from 'vue-content-loader';
  import { getModeloChecklistByID } from '@/services/ModeloChecklistService';

  interface IChecklistCompleto {
    checklist: Number;
    nome: String;
    tabela: String;
    diretorio: String;
    menus: String;
    impressao: String;
    api: String;
    nomereal: String;
    formulario: String;
    temoficinadigital: Number;
    completo: String;
    descricao: String;
    concessionarias: Array<String>;
    concessionariasAtivas: Array<String>;
    concessionariasDesabilitadas: Array<String>;
    checklistTotal: String;
    checklistTotalUltimosSeteDias: String;
    ultimoChecklistFeitoDataHora: String;
  }

  export default {
    name: "visualizarChecklistModal",
  
    props: {
      dadosChecklist: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: true,
      }
    },
  
    components: {
      ContentLoader
    },

  
    setup(props) {
      let animate = ref(true);
      let dadosCompletosChecklist = ref<IChecklistCompleto>({
        checklist: 0,
        nome: "",
        tabela: "",
        diretorio: "",
        menus: "",
        impressao: "",
        api: "",
        nomereal: "",
        formulario: "",
        temoficinadigital: 0,
        completo: "",
        descricao: "",
        concessionarias: [],
        concessionariasAtivas: [],
        concessionariasDesabilitadas: [],
        checklistTotal: "",
        checklistTotalUltimosSeteDias: "",
        ultimoChecklistFeitoDataHora: "",
      });
  
      watch(() => props.dadosChecklist,
      () => {
        getChecklistInfo(props.dadosChecklist.checklist);
      })
  
      const consultorModal: any = ref({});
  
      async function getChecklistInfo(codChecklist) {
        animate.value = true;
  
        dadosCompletosChecklist.value = await getModeloChecklistByID(codChecklist);
  
        animate.value = false;
      }
  
      return {
        animate,
        consultorModal,
        dadosCompletosChecklist
      }
    }
  }
  