
import moment from 'moment';
import Datatable from "@/components/datatable/Datatable.vue";
import { defineComponent, reactive, ref, Ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ContentLoader } from 'vue-content-loader';
import useEmitter from '@/composables/Emmiter';
import { getModelosChecklist } from '@/services/ModeloChecklistService';
import { getTipoApp } from '@/services/ModeloChecklistService';
import VisualizarChecklist from './components/VisualizarChecklist.vue';

interface Option {
  text: string;
  value: string | number; 
}

export default defineComponent({
  name: "cadastros-concessionaria-list",
  components: {
      Datatable,
      ContentLoader,
      VisualizarChecklist
  },

  setup() {
   const data:any = reactive({
        info: {
          current_page: 1,
          data: [],
          first_page_url: "",
          from: 1,
          last_page: 7,
          last_page_url: "",
          links: [],
          next_page_url: "",
          path: "",
          per_page: 50,
          prev_page_url: null,
          to: 0,
          total: 0
        },
        acampaatualizacao: 0,
        cnpj: 0,
        codAtria: 0,
        codConcessionaria: 0,
        logomarca: 0,
        marca: 0,
        nomeFantasia: 0,
        dadosTipoApp: [],
			});

    let page = ref(1);
    const concessionaria:any = ref([]); ;
    const router = useRouter();
    const contagempagina =  20
    const contagemArray = 30
    let animate = ref(true);
    let databruta = concessionaria.acampaatualizacao;
    const dadosConcessionaria:any = ref([]);
    const auxDadosConcessionaria:any = ref([]);
    const fastFilter:any = ref("");
    const codconcessionaria:any = ref({});
    const dadosconcessionaria2 = ref({});
    const codMarcaselect:any = ref({})
    const filtroConsultor:any = ref({})
    const filtroCodatria:any = ref({})
    const dadosChecklist = ref({});
    const dadosconcessionariaEspecifica2 = ref({});
    let postData: any = ref({});  
    const emitter = useEmitter();
    const codConcessionaria = ref(0);
    const dadosTipoApp: Ref<Option[]> = ref([]);
    const tipoAppSelecionado = ref<string>("all");

    onMounted(async () => {
      listaConcessionarias(); 
      listaTipoApp();
    });

    function formatarData(databruta) {
      if (databruta) {                                                                                  
        return moment(String(databruta)).format('DD/MM/YYYY HH:mm')
      }
    }

    function visualizarChecklist(checklist){
      dadosChecklist.value = checklist;
    }

    function goToParams(routeName, concessionariaRef){
				router.push(
					{
						name: routeName,
						params: {
							codConcessionaria: concessionariaRef.codConcessionaria,         
						}      
					}   
				);
		}; 

    const delayTimer = ref();

    function filterData(){
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function() {
        trataInfo(1, true);
      }, 300);
    }

    async function listaConcessionarias(){
        animate.value = true;
				let response = await getModelosChecklist();
        auxDadosConcessionaria.value = [...response];
        trataInfo(1);
        animate.value = false;   
    }

    async function listaTipoApp()
    {
      const response = await getTipoApp();
      dadosTipoApp.value = response.map((tipoApp) => (
      {
        text: tipoApp.descricao,
        value: tipoApp.tipoApp,
      }));
    }

    function trataInfo(page = 1, filter = false)
    {
      let baseDadosConcessionaria = [];

      if(filter && fastFilter.value)
      {
        if(filter && (fastFilter.value || tipoAppSelecionado.value) && tipoAppSelecionado.value != 'all')
        {
            baseDadosConcessionaria = auxDadosConcessionaria.value.filter(checklist =>(checklist.nome.toLowerCase().includes(fastFilter.value.toLowerCase()) || checklist.nomereal.toLowerCase().includes(fastFilter.value.toLowerCase()) || checklist.tabela.toLowerCase().includes(fastFilter.value.toLowerCase()) || checklist.checklist.toString().includes(fastFilter.value)) && (!tipoAppSelecionado.value || checklist.tipoApp === tipoAppSelecionado.value));    
        }else
        {
          baseDadosConcessionaria = auxDadosConcessionaria.value.filter(checklist =>(checklist.nome.toLowerCase().includes(fastFilter.value.toLowerCase()) || checklist.nomereal.toLowerCase().includes(fastFilter.value.toLowerCase()) || checklist.tabela.toLowerCase().includes(fastFilter.value.toLowerCase()) || checklist.checklist.toString().includes(fastFilter.value)));
        }
      }else
      {
        if(tipoAppSelecionado.value && tipoAppSelecionado.value !== "all")
        {
          baseDadosConcessionaria = auxDadosConcessionaria.value.filter(checklist => checklist.tipoApp === tipoAppSelecionado.value);
        }else
        {
          baseDadosConcessionaria = auxDadosConcessionaria.value;
        }
      }

      data.info.total = baseDadosConcessionaria.length;
      data.info.last_page = Math.ceil((baseDadosConcessionaria.length / data.info.per_page)) ?? 0;

      data.info.current_page = page;
      const start = data.info.per_page * (page - 1);
      const end =  data.info.per_page * (page);
      if(data.info.last_page == page){
        data.info.to = baseDadosConcessionaria.length;
      } else if(end > baseDadosConcessionaria.length){
        data.info.to = baseDadosConcessionaria.length;
      } else {
        data.info.to = end;
      }

      data.info.data = baseDadosConcessionaria.slice(start, end);
    }
    
     function goTo(url){
				router.push(url)
			};

    return {
      concessionaria,
      visualizarChecklist,
      dadosChecklist,
      goTo,
      listaConcessionarias,
      data,
      animate,
      page,
      contagempagina,
      contagemArray,
      dadosConcessionaria,
      dadosconcessionaria2,
      dadosconcessionariaEspecifica2,
      goToParams,
      formatarData,
      trataInfo,
      codconcessionaria,
      codMarcaselect,
      filtroConsultor,
      filtroCodatria,
      fastFilter,
      filterData,
      dadosTipoApp,
      tipoAppSelecionado,
      listaTipoApp,
    };
  },
});
